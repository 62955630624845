.topup-btn {
  margin-left: 18px;
  margin-top: 25px;
  margin: auto;
}

.checkout-sub-head {
  font-size: 21px;
  /* padding-top: 20px; */
  display: flex;
  width: 100%;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  margin-top: 20px;
  padding: 10px;
}

.checkout-sub-head2 {
  font-size: 21px;
  padding-top: 20px;
  display: flex;
}

.checkout-alert {
  font-size: 21px;
  width: 85%;
  /* padding-top: 20px; */
}

.charge-info {
  margin: 8px 0 20px 0;
}
