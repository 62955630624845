.startup-button {
  background-color: #00569D;
  border-color: #00569D;
  border-radius: 20px;
  color: white;
  font-size: 20px;
  height: 60px;
  margin: 40px 0px;
}

.startup-button:hover {
  background-color: #00569D;
  color: white;
  border-color: white;
}

.startup-button:focus {
  background-color: #00569D;
  color: white;
  border-color: white;
}

@media only screen and (max-height: 700px) {
  .startup-button {
    height: 40px;
    margin: 20px 0px;
  }
}

@media only screen and (max-height: 600px) {
  .startup-button {
    height: 50px;
    margin: 30px 0px;
  }
}
