.divider-cont {
  display: flex;
  padding: 10px;
  width: 100%;
}

.divider-items {
  width: 100%;
}

.divider-text {
  background: white;
  position: absolute;
  width: 10%;
}

hr.divider {
  border: 1px solid #7d7d7d;
}

.icon-cont {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 75px;
}

.windows-background {
  height: 50px;
  width: 50px;
  background-color: blue;
  border-radius: 50%;
  padding: 10px;
}

.google-icon {
  color: red;
  font-size: 50px;
  margin-right: 20px;
}

.windows-icon {
  color: white;
  font-size: 30px;
}

.startup-link {
  color: #00569D;
  cursor: pointer;
  text-align: center;
}
