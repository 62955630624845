.row-container {
  align-items: center;
  display: flex;
  padding: 5px 0px;
}

.index-container {
  width: 35px;
}

.select-container {
  width: calc(100% - 60px);
}

.delete-container {
  color: grey;
  margin-left: 7px;
}

.list-box-style {
  border: 1px solid lightgrey;
  height: 150px;
  padding: 5px;
}

.opt-image {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
