.trunk_panel_active {
  width: calc(100% - 600px);
}

.trunk_panel_inactive {
  width: 100%;
}

.trunk_top_container {
  height: 40px;
  margin: 5px 0px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.trunk_bottom_container {
  height: calc(100% - 50px);
  padding: 5px;
}

.trunk_add_general_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trunk_add_credentials_heading {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
}

.trunk_add_credentials_row {
  height: calc(100% - 100px);
  width: 100%;
  overflow: auto;
}

.trunk_add_credentials_addbutton {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 50px;
  width: 100%;
}

.trunk_add_credentials_rowsingle {
  display: flex;
  width: 100%;
  height: 40px;
}

.trunk_add_credentials_delbutton {
  width: 5%;
  padding: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.trunk_add_modifiers_intheading {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 400;
}

.trunk_add_modifiers_intsection {
  display: flex;
  margin-top: 5px;
}

.trunk_add_modifiers_rulheading {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
  height: 50px;
  display: flex;
  align-items: center;
}

.trunk_add_modifiers_heading {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
}

.trunk_add_modifiers_row {
  height: calc(100% - 40px);
  width: 100%;
  overflow: auto;
}
