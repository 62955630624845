.add-action-container {
  padding-top: 5px;
  height: 40px;
}

.editor-utils {
  height: 60px;
}

.editor-options {
  height: 60px;
}

.cyto-container {
  height: calc(100% - 60px);
  width: 100%;
}

.editor-style {
  height: 100%;
}

.editor-spacing {
  padding: 10px;
}

.editor-utils-container {
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.nodeactions-container button {
  margin-right: 10px;
}

.undo-button {
  background-color: lightgray;
  border-color: lightgray;
}

.undo-button .undo-image {
  width: 50%;
  height: 50%;
  margin: auto;
  display: flex !important;
}

.undo-button svg {
  vertical-align: top !important;
}

.editor-style {
  height: 100%;
}

.editor-spacing {
  padding: 10px;
}

.error-label {
  color: red;
  padding: 5px;
  text-align: center;
}
