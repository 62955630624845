.stepmodal-component {
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  min-height: 250px;
  width: 600px;
}

.stepmodal-footer {
  display: flex;
  justify-content: space-between;
}

.stepmodal-footer-end {
  display: flex;
  justify-content: flex-end;
}

.filter-space {
  padding-bottom: 5px;
}

.error-parent {
  display: flex;
}

.alert {
  background-color: #f44336;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  width: 95%;
}

.info-text {
  padding-bottom: 15px;
}

.tick-icon {
  height: 125px;
  width: 125px;
}

.allset-tag {
  margin-bottom: 15px;
}

.tag-space {
  margin-bottom: 15px;
}

.input-parent {
  align-items: center;
  display: flex;
}

.select-style {
  width: 100%;
}

.row-disable {
  opacity: 70%;
}

.row-disable:hover {
  background-color: unset !important;
}

.back-button-style {
  border-radius: 5px;
}

.back-button-style:hover {
  border-color: var(--userPrimaryColor);
  color: var(--userPrimaryColor);
}

.progress-bar-cont {
  display: flex;
  justify-content: center;
}

.progress-bar-style {
  width: 95%;
}

.license-cont {
  align-items: center;
  display: flex;
  padding: 5px;
  width: 100%;
}

.license-name {
  font-size: 11px;
  margin-right: 15px;
}

.license-icon-valid {
  visibility: hidden;
}

.license-icon-invalid {
  color: #efac17;
  visibility: visible;
}

.SelectUsers__infocontainer {
  align-items: center;
  display: flex;
  padding: 15px;
  text-align: center;
  width: 100%;
}

.SelectUsers__infotext {
  padding: 5px 15px;
  width: calc(100% - 110px);
}

.SelectUsers__infoRefresh {
  width: 110px;
}

.ConfigUsers__ColumnHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ConfigUsers__CallingPlanDropDownBody {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 10px;
}

.ConfigUsers__CallingPlanDropDown {
  margin: 0px !important;
}
.ConfigUser_Text{
  margin-right: 15px;
}