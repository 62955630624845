.config_account_warn_top_container {
  margin-bottom: 10px;
}

.config_account_warn_bottom_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.config_account_element {
  font-size: 16px;
  margin: 2px;
}

.config_account_link {
  font-size: 16px;
  padding: 0px 2px;
  margin: 2px;
}

.retry{
  margin:auto;
}

.config_account_progress {
  width: 95%;
}

.config_account_progress_bar {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.config_account_progress_body {
  text-align: center;
}
