.startup-image-panel-container {
  height: 100%;
}

.startup-image-style {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.startup-img-content {
  color: white;
  padding: 30px;
}

.startup-img-width {
  width: 45%;
}

.startup-text {
  font-size: 21px;
  font-weight: 500;
  text-align: center;
}

@media only screen and (max-height: 700px) {
  .startup-text {
    font-size: 18px;
    font-weight: 300;
  }
}

@media only screen and (max-height: 500px) {
  .startup-text {
    font-size: 15px;
    font-weight: 100;
  }
}
