.step-component {
  height: 100%;
}

.stepmodal-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.stepmodal-footer-end {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.filter-space {
  padding-bottom: 5px;
}

.error-parent {
  display: flex;
}

.alert {
  background-color: #f44336;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  width: 95%;
}

.info-text {
  padding-bottom: 15px;
}

.tick-icon {
  height: 125px;
  width: 125px;
}

.allset-tag {
  margin-bottom: 15px;
}

.tag-space {
  margin-bottom: 15px;
}

.input-parent {
  align-items: center;
  display: flex;
}

.select-style {
  width: 100%;
}

.back-button-style {
  border-radius: 5px;
}

.back-button-style:hover {
  border-color: var(--userPrimaryColor);
  color: var(--userPrimaryColor);
}

.finish-tag-parent {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.finish-text-div {
  text-align: center;
  padding-bottom: 15px;
}

.msteams-layout {
  background: #fff;
  height: 100%;
}

.msteams-layout .layout-header {
  background: #fff;
  padding: 5px;
}

.msteams-layout .layout-content {
  margin: 10px;
  overflow: auto;
}

.msteams-layout .layout-footer {
  background: #fff;
  height: 32px;
  padding: 0px;
}
