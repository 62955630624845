.row_table_list {
  max-height: 70px;
  overflow: auto;
}

.routing_add_heading {
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100% - 150px);
  overflow: auto;
}

.routing_add_heading_button {
  display: flex;
  align-items: center;
  width: 33%;
}

.routing_panel_active {
  width: calc(100% - 520px);
}

.routing_panel_inactive {
  width: 100%;
}

.route_array_list {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}