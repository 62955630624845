.row-button-style {
  background-color: #f3f2f0;
  border-color: #f3f2f0;
  box-shadow: none !important;
  color: grey;
  padding: 0 !important;
  height: 25px !important;
  width: 25px !important;
  min-width: 25px !important;
}

.row-button-style:focus {
  background-color: #f3f2f0;
  border-color: #f3f2f0;
  box-shadow: none !important;
  color: grey;
}

.row-button-style:hover {
  background-color: var(--userPrimaryColor);
  border-color: #f3f2f0;
  box-shadow: none !important;
  color: white;
}

.row-button-style:hover .anticon {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.rowmoreactionsoptions {
  align-items: center;
  display: flex;
  color: grey;
}
