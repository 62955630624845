.ActionsLibrary__Container {
  height: 100%;
  padding-left: 15px;
}

.ActionsLibrary__Heading {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 1px solid #eeeeee;
  font-size: 20px;
}

.ActionsLibrary__Section {
  overflow-y: auto;
  margin-top: 15px;
  padding-right: 5px;
  height: calc(100% - 75px);
}

.ActionsLibrary__DragItem {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  height: 45px;
  border: 1px solid #eeeeee;
  align-items: center;
  margin-bottom: 10px;
  cursor: move;
}

.ActionsLibrary__DragItem:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}

.ActionsLibrary__Img {
  margin-right: 15px;
  height: 25px;
  width: 25px;
}
