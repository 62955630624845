.custom-modalwrapper .ant-modal {
  min-width: 300px;
  max-width: fit-content !important;
}

.custom-modal {
  width: 80% !important;
  min-width: 150px !important;
}

.voice-user-modal {
  width: 50em !important;
}

.custom-modal .footer-container {
  display: flex;
}
