.edit-cont {
  height: 100%;
  padding: 0px 15px;
}

.edit-body {
  height: 75px;
  margin-bottom: 5px;
}

.edit-heading {
  color: black;
  font-weight: 400;
}

.edit-input-style {
  margin-top: 10px;
}

.edit-custom-style {
  margin: 5px 0;
  width: 100%;
}

.billing-label {
  color: rgb(32, 11, 11);
  margin: 3px 0;
  /* border-bottom: 1px solid rgb(32, 11, 11); */
}

.call-plan-label {
  margin: 4px 0 4px 0;
  display: flex;
  cursor: pointer;
  border: 1px solid lightgray;
}

.calling-plan-block {
  padding: 5px;
  border: 1px solid lightgrey;
  margin-top: 10px;
}

.delete-call-plan-icon {
  display: none;
}

.call-plan-label:hover .delete-call-plan-icon {
  display: block;
  margin: auto;
  width: 15%;
}

.calling-plan-modal .ant-modal-body {
  width: 45vw;
}

.calling-plan-table .ant-table-body {
  height: 25vh;
}

.call-plan-name {
  width: 100%;
  height: 32px;
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spin-style-small {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spin-style-small .ant-spin-lg .ant-spin-dot {
  font-size: 30px !important;
}
