.call-flow-main-cont {
  height: 100%;
}

.call-flow-utils-container {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-end;
}

.call-flow-table-container {
  height: calc(100% - 40px);
  padding-top: 15px;
}

.call-flow-utils-parent {
  display: flex;
}

.call-flow-utils-input {
  margin-right: 5px;
}

.call-flow-edit-cont {
  height: 100%;
}

.call-flow-edit-body {
  height: 100%;
}

.call-flow-edit-inner-body {
  display: flex;
  height: 100%;
}

.call-flow-active-style {
  height: 100%;
  width: calc(100% - 290px);
}

.call-flow-inactive-style {
  height: 100%;
  width: 100%;
}

.zero_button-style {
  margin: 0px 40px;
}

.dialpad_button-style {
  height: 35px;
  width: 40px;
}

.AddEditFlow__Conatiner {
  display: flex;
  align-items: center;
  min-width: 300px;
  text-align: center;
}

.AddEditFlow__TextField {
  width: 100%;
}

.AddEditFlow__Body {
  min-width: 300px;
}

.AddDialSpeech__Deselected:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}

.AddDialSpeech__Deselected:hover {
  color: #40a9ff;
  border-color: #40a9ff;
}

.AddDialSpeech__Selected {
  color: var(--userPrimaryColor);
  border-color: var(--userPrimaryColor);
}

.AddDialSpeech__Selected:focus {
  color: var(--userPrimaryColor);
  border-color: var(--userPrimaryColor);
}

.AddDialSpeech__Selected:hover {
  color: var(--userPrimaryColor);
  border-color: var(--userPrimaryColor);
}