.CustomNodeWidget__Node {
  align-items: center;
  background-color: white;
  border: 1px solid grey;
  color: grey;
  display: flex;
  font-size: 15px;
  height: 60px;
  justify-content: center;
  padding: 10px;
  width: 250px;
}

.CustomNodeWidget__IconCont {
  align-items: center;
  display: flex;
  justify-content: center;
}

.CustomNodeWidget__Name {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: calc(100% - 40px);
  padding: 0px 5px;
  text-align: center;
  white-space: nowrap;
}

.CustomNodeWidget__Icon {
  border: 1px solid grey;
  height: 35px;
  padding: 5px;
  width: 35px;
}

.node-port-in {
  width: 12px;
  height: 12px;
  border-radius: 7px;
  background: lightgrey;
  cursor: pointer;
}

.node-port-in:hover {
  background: mediumpurple;
}

.node-port-out {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  border-radius: 10px;
  background: lightgrey;
  cursor: pointer;
}

.node-port-out:hover {
  background: lightcoral;
}

.CustomNodeWidget__Delete {
  cursor: pointer;
}

.CustomNodeWidget__Inport-Container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -7px;
  width: 100%;
}

.CustomNodeWidget__Outport-Container {
  bottom: -7px;
  position: absolute;
  width: 100%;
}

.InbuiltWidget__Outport-Container {
  bottom: -7px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 15px;
}

.CustomNodeWidget__Outport-Cont {
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
}

.CustomNodeWidget__Outports-Cont {
  display: flex;
  justify-content: center;
  padding: 0px 15px;
}

/* show image on hover */

.CustomNodeWidget__Node > .CustomNodeWidget__Delete {
  visibility: hidden;
}

.CustomNodeWidget__Node:hover > .CustomNodeWidget__Delete {
  visibility: visible;
}

.CustomNodeWidget__Inport-Container > .Inport_Style {
  visibility: hidden;
}

/* showing inports on hover but commenting it out as we dont need show them only on dragging */
/* .CustomNodeWidget__Inport-Container:hover > .Inport_Style {
  visibility: visible;
} */

.CustomNodeWidget__Outport-Container > .Outport_Style {
  visibility: hidden;
}

.CustomNodeWidget__Outport_And_Node:hover
  > .CustomNodeWidget__Outport-Container
  > .Outport_Style {
  visibility: visible;
}

.InbuildNodeWidget__Node {
  background-color: #68678a;
  color: white;
  display: flex;
  flex-direction: column;
  height: 85px;
  padding: 10px;
  width: 330px;
}

.InbuildNodeWidget__Node__Text {
  height: 50%;
  width: 100%;
  text-align: center;
}