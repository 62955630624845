.ptt-logo-cont {
  align-items: center;
  display: flex;
  height: 62px;
}

.ant-layout-sider-children {
  height: calc(100vh - 48px);
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ptt-logo-style {
  width: 100%;
}

.home-menu-style {
  height: calc(100% - 80px);
  position: relative;
}

.top-sidebar-items-reseller {
  height: calc(100% - 52px);
  overflow: auto;
}

.bottom-sidebar-items-reseller {
  height: 52px;
}

.top-sidebar-items-normal {
  height: calc(100% - 104px);
  overflow: auto;
}

.bottom-sidebar-items-normal {
  height: 104px;
}

.list-style {
  padding: 5px 25px !important;
}

.ant-spin-nested-loading {
  position: relative;
  height: 100%;
}

.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
  height: 100%;
}

.list-style-expanded {
  align-items: center;
  display: flex;
  padding: 5px 25px !important;
}

.my-bot-style {
  align-items: center;
  bottom: 40px;
  display: flex;
  line-height: 40px !important;
  padding: 5px 25px !important;
  position: absolute !important;
  min-height: 55px;
}

.my-acc-style {
  align-items: center;
  bottom: 0px;
  display: flex;
  line-height: 10px !important;
  padding: 5px 25px !important;
  position: absolute !important;
  min-height: 55px;
}

.home-cont-style {
  height: 100%;
  margin: 0px 16px;
}

.stats-cont-style {
  margin: 0 0 0 15px;
}

.logout-icon-style {
  margin-right: 5px;
}

.home-layout-style {
  min-height: 100vh;
  height: 100%;
}

.popover-parent {
  margin-right: 10px;
}

.acc-option {
  cursor: pointer;
}

.site-layout {
  height: 100%;
}

.site-layout .site-layout-background {
  background: #fff;
  height: calc(100% - 70px);
  padding: 10px;
}

.stats-layout-bg {
  /* padding: 0; */
  height: 100%;
}

.breadcrumb-style {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 25px;
  margin: 15px 0px;
}

.icon-small {
  font-size: 18px !important;
  line-height: 15px !important;
}

.icon-big {
  font-size: 25px !important;
  line-height: 15px !important;
}

.Home__Comingsoon {
  align-items: center;
  display: flex;
  font-size: 40px;
  height: 100%;
  justify-content: center;
}

#sidebar-native-menu1 .ant-menu-item-selected {
  background: #808080 !important;
}

#sidebar-native-menu1 .ant-menu-item-selected:hover {
  background: #808080 !important;
}

#sidebar-native-menu2 .ant-menu-item-selected {
  background: #808080 !important;
}

#sidebar-native-menu2 .ant-menu-item-selected:hover {
  background: #808080 !important;
}

/* common styles */

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.align-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.text-align-center {
  text-align: center;
}

/* common node label spacing */
.label-spacing {
  padding: 5px 0px;
  white-space: pre-wrap;
}

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 4px 0;
}

.ant-tabs-content {
  height: calc(100% - 72px);
}

.ant-tabs-tab-active,
.ant-tabs-tab:hover {
  color: black !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black !important;
}

.ant-tabs-ink-bar {
  background-color: #353148 !important;
}

/* styles for normal antd table */

.ant-table-selection-column {
  background-color: white;
}

td.ant-table-column-sort {
  background-color: white;
}

.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: #f3f2f0;
}

.ant-table-tbody > tr.ant-table-row-selected td {
  background-color: #e0dfdd;
}

/* ant steps title style */

.ant-steps-item-process .ant-steps-item-icon {
  background: var(--userPrimaryColor);
  border-color: var(--userPrimaryColor);
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--userPrimaryColor);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--userPrimaryColor);
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--userPrimaryColor);
}

.ant-steps-item-title {
  white-space: nowrap;
}

/* ant radio grp styles */

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: var(--userPrimaryColor);
  color: white;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
  background-color: var(--userPrimaryColor);
  color: white;
}

.ant-radio-button-wrapper:hover {
  color: var(--userPrimaryColor);
}

/* styles for antd form item */

.ant-form-item {
  margin-bottom: 0px;
}

.ant-form-item-explain {
  min-height: 0px;
}
