.stats-cont {
  height: 100%;
}

.site-layout-background.stat-sidebar {
  /* height: 100%;*/
  padding: 0;
  background: white;
}

.stat-sidebar .site-layout-background {
  height: 100%;
  /* margin-right: 5px; */
}

.stat-sidebar .ant-menu-root.ant-menu-inline {
  /* background: #c7d0d9; */
  /* height: calc(100vh - 50px); */
  height: auto !important;
  border-right: 1px solid #fafafa;
}

.stat-sidebar .ant-menu-sub.ant-menu-inline {
  /* background: #c7d0d9; */
}

.stat-sidebar .ant-menu-sub.ant-menu-inline > .ant-menu-item {
  /* background: #e9edf2; */
  cursor: pointer;
}

.stat-sidebar .ant-layout-sider-children {
  height: calc(100vh - 73px);
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: none;
}

::-webkit-scrollbar-thumb {
  min-height: 100px;
  max-height: 100px;
  border-radius: 3px;
  background: #d9d9d9;
}

.site-layout .stat-sidebar .site-layout-background {
  padding: 0;
  /* background: #f0f2f5; */
  /* background: white; */
}

.no-dashboard-txt {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stat-sidebar .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

.stat-sidebar .ant-menu-inline .ant-menu-item {
  margin-top: 0;
}

.stat-cont-wrap {
  display: flex;
  height: 100%;
}

.stat-sidebar-wrap {
  width: 200px;
  height: 100%;
  margin-right: 5px;
}

.stat-title {
  font-size: 20px;
  font-weight: 700;
  height: 25px;
  margin: 15px 0;
}
