.Layout__Body {
  height: 100vh;
  width: 100vw;
}

.info-children {
  height: 100%;
  padding: 15px;
  overflow: hidden;
}

.type-body {
  height: 75px;
  margin-bottom: 5px;
}

.type-heading {
  color: black;
  font-weight: 400;
}

.input-style {
  margin: 5px;
}

.spinner-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* start-up styles */

.startup-container {
  display: flex;
  height: 100%;
}

.startup-container .ant-input-affix-wrapper > input.ant-input {
  font-size: 20px;
}

.startup-container .ant-spin-nested-loading {
  width: 100%;
}

.startup-image-section {
  width: 50%;
}

.startup-field-section {
  width: 50%;
}

.startup-prefix-style {
  margin-right: 10px;
}

.start-up-input {
  border-radius: 20px;
  height: 68px;
  margin: 5px 0px;
}

.sp-dd .ReactFlagsSelect-module_selectBtn__19wW7 {
  height: 50px;
  border-radius: 20px;
  margin: 5px 0px;
}

.startup-field-container {
  height: 100%;
  padding: 15px;
}

.startup-field-inputs {
  width: 70%;
}

.startup-title {
  color: #00569D;
  font-size: 50px;
  font-weight: 700;
  padding-bottom: 50px;
}

.startup-title-setpassword {
  color: #00569D;
  font-size: 50px;
  font-weight: 700;
  padding-bottom: 50px;
}

.startup-subtitle-setpassword {
  color: #00569D;
  font-size: 14px;
}

.startup-result-container {
  text-align: center;
}

.startup-info-text {
  color: #807f90;
  font-size: 20px;
  margin-bottom: 10px;
}

.startup-check-mail {
  color: #00569D;
  font-size: 30px;
  margin-bottom: 10px;
}

.startup-define-password {
  color: #807f90;
  font-size: 15px;
}

/* react flags select custom styles start */
.ReactFlagsSelect-module_selectOptions__3LNBJ {
  margin-top: 0px !important;
  max-height: 150px !important;
}

.ReactFlagsSelect-module_filterBox__3m8EU {
  padding-top: 0px !important;
}

.ReactFlagsSelect-module_filterBox__3m8EU input {
  width: 100% !important;
  margin: 0px !important;
  border: 1px solid lightgrey !important;
}
/* react flags select custom styles start */

@media only screen and (max-height: 900px) {
  .startup-info-text {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .startup-title {
    font-size: 45px;
    padding-bottom: 45px;
  }
  .startup-title-setpassword {
    font-size: 45px;
    padding-bottom: 0px;
  }
  .startup-subtitle-setpassword {
    padding-bottom: 45px;
  }
}

@media only screen and (max-height: 800px) {
  .startup-info-text {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .startup-title {
    font-size: 40px;
    padding-bottom: 40px;
  }
  .startup-title-setpassword {
    font-size: 40px;
    padding-bottom: 0px;
  }
  .startup-subtitle-setpassword {
    padding-bottom: 40px;
  }
}

@media only screen and (max-height: 700px) {
  .startup-info-text {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .startup-check-mail {
    font-size: 20px;
  }
  .startup-define-password {
    font-size: 13px;
  }
  .startup-title {
    font-size: 35px;
    padding-bottom: 35px;
  }
  .startup-title-setpassword {
    font-size: 35px;
    padding-bottom: 0px;
  }
  .startup-subtitle-setpassword {
    padding-bottom: 35px;
  }
  .start-up-input {
    height: 50px;
  }
  .sp-dd .ReactFlagsSelect-module_selectBtn__19wW7 {
    height: 45px;
  }
}

@media only screen and (max-height: 600px) {
  .startup-info-text {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .startup-check-mail {
    font-size: 15px;
  }
  .startup-define-password {
    font-size: 11px;
  }
  .startup-title {
    font-size: 25px;
    padding-bottom: 25px;
  }
  .startup-title-setpassword {
    font-size: 25px;
    padding-bottom: 0px;
  }
  .startup-subtitle-setpassword {
    padding-bottom: 25px;
  }
  .start-up-input {
    height: 40px;
  }
  .sp-dd .ReactFlagsSelect-module_selectBtn__19wW7 {
    height: 40px;
  }
}

.ant-table-thead>tr>th {
  font-weight: 600;
}

.Userdetail__Content {
  margin-bottom: 10px;
  margin-right: 0px !important;
}

.Userdetail__ButtonBody {
  margin-top: 25px;
  text-align: center;
}

.Userdetail__BuildingIcon {
  font-size: 15px;
}

.Userdetail__subtitle_body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.UserDetailPopup {
 min-height: 300px;
}

.UserDetailPopup > .ant-result {
  padding: 0px !important;
}

.UserDetailPopup > .ant-result > .ant-result-icon {
  margin-bottom: 0px !important;
}

.Userdetail__Content .ant-input-affix-wrapper>input.ant-input {
  font-size: 15px;
  margin-left: 5px;
}

.signin-colored-logo, .signup-colored-logo, .setpassword-colored-logo {
  width: 40%;
}

.page-data {
  color: black;
}

.pagination {
  display: flex;
}

.pagination span:after {
  content: "|";
  margin: 0 9px;
}

.pagination span:last-child:after {
  content: '';
  margin: 0 9px;
}