.setting-body-container {
  height: 100%;
}

.setting-tab {
  height: 100%;
}

.setting-tab .ant-tabs-nav {
  margin: 0;
}

.setting-tab .ant-tabs-content {
  height: 100%;
}

.setting-tab-children {
  padding: 10px 0px !important;
  overflow-x: scroll;
}

.container {
  height: 100%;
}

.settings-topbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
}

.setting-tab-label {
  font-size: 17px;
  margin: 0px 0px 12px 0px;
  width: 250px;
}

.setting-tab-element {
  margin-bottom: 5px;
  width: 250px;
}

.setting-tab-element-address {
  margin-bottom: 5px;
  width: 600px;
}

.setting-tab-dropdown {
  width: 100%;
}

.setting-country-dd-body {
  display: flex;
  align-items: center;
}

.setting-country-dd-text {
  margin-left: 10px;
}

.settings-uneditable-div {
  padding: 4px 11px;
  height: 32px;
  border: 1px solid lightgray;
}

.settings-country.country-select {
  margin: 0 0 15px 0;
  width: 251px;
}

.spanish-excempt-checkbx {
  margin: 0 0 15px 15px;
}

.settings-country .flag-select__btn {
  width: 100%;
}

.settings-country .flag-select__options {
  width: inherit !important;
}

.settings-country .flag-select__btn:after,
.flag-select__btn[aria-expanded='true']:after {
  margin-left: -14px !important;
}

.settings-country .flag-select__btn:before {
  width: 97%;
}

.flag-select.settings-country .filterBox input {
  padding-left: 6px;
  height: 2.3em !important;
}

.settings-country .flag-select__option {
  padding-left: 4px !important;
}

.settings-country .flag-select__option__icon,
.country-select .flag-select__option__icon,
ul .flag-select__option__icon {
  top: 0 !important;
}

.change-password-title-div {
  height: 28px;
  font-size: 16px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.change-password-input {
  margin-bottom: 8px;
  width: 250px;
}

.invite-user-mailbox {
  height: 420px;
  width: 450px;
  display: flex;
  flex-direction: column;
  margin: 24px;
  overflow: auto;
}
