.selectabletable-row {
  cursor: pointer;
}

.selectabletable-row td {
  background: transparent !important;
}

.selectabletable-row:not(.selected-row):hover {
  background-color: #f3f2f0;
}

.selectabletable-row.selected-row {
  background-color: #e0dfdd;
}

.selectabletable-row.selected-row:hover .row-button-style {
  background-color: #e0dfdd;
  border-color: #e0dfdd;
  box-shadow: none !important;
  color: grey;
}

.selectabletable-row .child-client-access-button {
  visibility: hidden;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  opacity: 0;
}

.selectabletable-row:hover .child-client-access-button {
  visibility: visible;
  opacity: 1;
}
