.Diagram__Node-Highlight {
  background-color: #12d69b;
}

.Diagram__Node-Highlight:hover {
  border: 1px solid black;
}

.Diagram__DropContainer {
  height: 100%;
  width: 100%;
}

/* prevent hiding links on zoom out */
.css-12q0bj3 {
  overflow: visible !important;
}
