.allset-icon {
  color: #41ad49;
  font-size: 250px;
  padding: 32px;
}

.allset-done-text {
  color: #939393;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 10px;
}

.allset-info-text {
  color: #939393;
  font-size: 15px;
}

.allset-image-cont {
  padding: 15px;
}

@media only screen and (max-height: 650px) {
  .allset-icon {
    font-size: 150px;
    padding: 25px;
  }
  .allset-done-text {
    font-size: 23px;
  }
  .allset-info-text {
    font-size: 13px;
  }
}

@media only screen and (max-height: 520px) {
  .allset-icon {
    font-size: 100px;
    padding: 15px;
  }
  .allset-done-text {
    font-size: 21px;
  }
  .allset-info-text {
    font-size: 11px;
  }
}

.allset-button-cont {
  padding-top: 15px;
}
