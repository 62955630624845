.primary-button-reseller {
  background-color: var(--resellerPrimaryColor);
  border-color: var(--resellerPrimaryColor);
  border-radius: 5px;
  color: white;
}

.primary-button-reseller:focus {
  background-color: var(--resellerPrimaryColor);
  border-color: white;
  color: white;
}

.primary-button-reseller:hover {
  background-color: var(--resellerPrimaryColor);
  border-color: white;
  color: white;
}

.secondary-button-reseller {
  background-color: white;
  border-color: grey;
  border-radius: 5px;
  color: grey;
}

.secondary-button-reseller:focus {
  background-color: var(--resellerPrimaryColor);
  border-color: var(--resellerPrimaryColor);
  color: white;
}

.secondary-button-reseller:hover {
  background-color: var(--resellerPrimaryColor);
  border-color: var(--resellerPrimaryColor);
  color: white;
}

.primary-button {
  background-color: var(--userPrimaryColor);
  border-color: var(--userPrimaryColor);
  border-radius: 5px;
  color: white;
}

.primary-button:focus {
  background-color: var(--userPrimaryColor);
  border-color: white;
  color: white;
}

.primary-button:hover {
  background-color: var(--userPrimaryColor);
  border-color: white;
  color: white;
}

.secondary-button {
  background-color: white;
  border-color: grey;
  border-radius: 5px;
  color: grey;
}

.secondary-button:focus {
  background-color: var(--userPrimaryColor);
  border-color: var(--userPrimaryColor);
  color: white;
}

.secondary-button:hover {
  background-color: var(--userPrimaryColor);
  border-color: var(--userPrimaryColor);
  color: white;
}
