.cp-button-body {
  display: flex;
  justify-content: space-between;
}

.cp-back-button-style {
  border-radius: 5px;
  white-space: normal;
}

.cp-back-button-style-reseller:hover {
  border-color: var(--resellerPrimaryColor);
  color: var(--resellerPrimaryColor);
}

.cp-back-button-style:hover {
  border-color: var(--userPrimaryColor);
  color: var(--userPrimaryColor);
}

.on-button-style {
  border-radius: 5px;
  white-space: normal;
}
