.MicrosoftTeams-Body {
  height: 100%;
}

.MicrosoftTeams-InnerBody {
  height: 100%;
}

.MicrosoftTeams-OptionBody-small {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 70px;
}

.MicrosoftTeams-OptionBody-big {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.MicrosoftTeams-TableBody-small {
  height: calc(100% - 70px);
}

.MicrosoftTeams-TableBody-big {
  height: calc(100% - 50px);
}

.user-connector-style {
  display: flex;
  align-items: center;
}

.connector-space {
  margin-right: 5px;
}

.spin-style {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ms-body {
  height: calc(100% - 50px);
  padding-top: 15px;
}

.ms-active-style {
  width: calc(100% - 315px);
}

.ms-inactive {
  width: 100%;
}

.panel-cont {
  display: flex;
  height: 100%;
}

.MsTeams-SearchContainer {
  display: flex;
  align-items: center;
}

.MsTeams-SearchField {
  margin-right: 5px;
}
