.CallFlowSideBar__NoTabsDiv {
  height: calc(100% - 0px);
  overflow: auto;
}

.CallFlowSideBar__NoTabsDiv .ant-upload-drag {
  height: 150px;
}

.CallFlowSideBar__Tabs {
  height: 100%;
}

.CallFlowSideBar__Tabs .ant-upload-drag {
  height: 150px;
}

.CallFlowSideBar__Tabs .ant-tabs-nav.ant-tabs-nav-animated {
  width: 100%;
}

.CallFlowSideBar__Tabs .ant-tabs-nav.ant-tabs-nav-animated div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.CallFlowSideBar__Tabs .ant-tabs-nav .ant-tabs-tab {
  padding: 5px 0px;
}

.CallFlowSideBar__Tabs .ant-tabs-tabpane {
  height: 100%;
}

.CallFlowSideBar__Tabs .ant-tabs-content {
  height: calc(100% - 47px);
  overflow: auto;
}

.CallFlowSideBar__Container {
  height: 100%;
  padding: 10px;
}

.CallFlowSideBar__Describtion {
  height: 150px;
  text-align: center;
}

.CallFlowSideBar__Icon {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
}

.CallFlowSideBar__Iconstyle {
  height: 40px;
  width: 40px;
}

.CallFlowSideBar__Label {
  font-size: 15px;
  height: 25px;
}

.CallFlowSideBar__Desc {
  font-size: 12px;
  height: 75px;
}

.CallFlowSideBar__Elements {
  height: calc(100% - 150px);
}
