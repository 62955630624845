.button-style {
  background-color: white;
  border-color: white;
  box-shadow: none !important;
  color: grey;
}

.button-style:focus {
  background-color: white;
  border-color: white;
  box-shadow: none !important;
  color: grey;
}

.button-style:hover {
  background-color: var(--userPrimaryColor);
  border-color: white;
  box-shadow: none !important;
  color: white;
}

.button-style:hover .anticon {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
