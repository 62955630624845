.Announcement-Upload-Container {
  height: 180px;
  overflow: auto;
  display: flex;
  align-items: center;
}

.Announcement-Topspace {
  margin-top: 5px;
}

.Announcement-Rightspace {
  margin-right: 5px;
}

.Announcement-Content-Spacing {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Announcement-Content-Spacing-bet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Announcement-Select {
  width: 125px;
}

.Announcement-Container {
  height: 260px;
  border: 1px solid lightgrey;
  padding: 5px;
  margin-top: 5px;
}

.Announcement-Container-file {
  height: 220px;
  border: 1px solid lightgrey;
  padding: 5px;
  margin-top: 5px;
}

.Announcement-titlehead {
  font-weight: bold;
}

.Announcement-RadioButton {
  display: block;
  height: 30px;
  line-height: 30px;
}

.Announcement-OptionBody {
  display: flex;
  align-items: center;
}

.Announcement-OptionBodySepertor {
  margin-right: 5px;
}

.Announcement-OptionBodyText {
  text-align: start;
}

.Announcement-OptionIcon {
  height: 15px;
  width: 15px;
}