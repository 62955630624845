.VoiceUserComponent__Box {
  border: 1px solid lightgrey;
  height: 180px;
  padding: 5px;
}

.VoiceUserComponent__RowBody {
  height: 35px;
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.VoiceUserComponent__RowIcon {
  margin-right: 10px;
}

.VoiceUserComponent__RowDeleteIcon {
  visibility: hidden;
}

.VoiceUserComponent__RowBody:hover > .VoiceUserComponent__RowDeleteIcon {
  visibility: visible;
}

.VoiceUserComponent__ModelSearch {
  width: 200px;
}

.VoiceUserComponent__ModelTitle {
  margin-top: 10px;
  font-size: 15px;
}

.VoiceUserComponent__ModelCheckBody {
  margin-top: 10px;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  height: 150px;
  overflow-y: auto;
  padding: 12px 0px;
}

.VoiceUserComponent__ModelCheckStyle {
  padding: 5px 0px;
}

.VoiceUserComponent__ModelEmpty {
  display: flex;
  align-items: center;
  height: 225px;
  width: 200px;
}

.VoiceUserComponent__Rows {
  height: calc(100% - 28px);
  overflow-y: auto;
}
