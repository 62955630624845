.phone-utils-text {
  font-weight: 600;
  font-size: 20px;
}

.phone-utils-input {
  margin-right: 5px;
}

.flag-img {
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0;
}

.phone-body {
  height: calc(100% - 40px);
  margin-top: 10px;
}

.phone-active-style {
  width: calc(100% - 290px);
}

.avoid-margin {
  margin: 0px !important;
}

.country-select,
.city-select {
  width: 21vw;
  text-align: left;
  margin: 0.75em 3em 3em 0;
}

.country-select-label,
.city-select-label {
  width: 21vw;
  margin: 0em 3.15em 0em 0;
}

.dd-labels {
  margin-top: 3em;
  display: flex;
}

.country-select .flag-select__btn {
  width: 89%;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  text-align: left;
  top: 0;
  height: 33px;
}

.flag-select__options {
  /* position: fixed; */
  padding: 0;
  width: 18vw;
}

button#select_flag_button:focus {
  outline: none;
}

.city-select .ant-select-selection-placeholder {
  color: #4d4d4d;
  font-size: 1em;
}

.select-group {
  display: inline-flex;
  /* width: 100%; */
  margin-bottom: 15px;
}

.phone-select {
  margin-top: 12px;
  /* width: 100%; */
  text-align: left;
}

.country-select .flag-select__btn {
  /* width: 89%; */
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  text-align: left;
}

.flag-select__option--placeholder {
  height: 25px;
  font-size: 14px;
}

.flag-select__option__label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.add-phoneno-modal-body {
  /*  min-width: 26em;
    max-width: 75em; */
  /* overflow-x: scroll; */
}

.add-phone-modal .ant-modal-content {
  /*  width: 65em;
    height: 46.5em; */
  /* max-width: 65em;
    min-width: 50em; */
  /* height: 81vh; */
  width: 70vw;
}

.add-phone-modal .ant-modal-body {
  /*  width: 917px;
    height: 541px; */
  /* max-width: 65em;
    min-width: 50em; */
  overflow: hidden;
  width: 70vw;
  height: 59vh;
}

.edit-custom-btn {
  width: 100%;
  margin: 5px 0;
}

.radio-style {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin: 15px 0;
  min-width: 87px;
}

/* .edit-target-style {
    height: 32px;
    width: 100%;
    display: content;
}

.target-label {
    min-width: 55px;
    max-width: 55px;
} */

.target-select {
  max-width: 177px;
  min-width: 177px;
  /* float: right; */
  margin: 15px 0;
}

@media (max-height: 650px) {
  .step-component.add-phoneno-modal-body {
    /*  overflow-x: hidden;
        height: 24em; */
  }

  .add-phone-modal .ant-modal-body {
    overflow: scroll;
    height: 40vh;
  }

  .add-phone-no-table .ant-table-body,
  .phone-no-checkout .ant-table-body {
    max-height: 30vh !important;
  }
}

@media (max-width: 725px) {
  /*  .step-component.add-phoneno-modal-body {
        overflow-x: hidden;
        height: 24em;
    }

    .add-phone-modal .ant-modal-body {
        overflow: hidden;
    } */

  .country-select .flag-select__btn {
    padding: 0 6px !important;
  }

  .flag-select__option {
    padding: 0 3px 3px 4px !important;
  }
}

.stepmodal-footer,
.stepmodal-footer-end {
  background-color: #fff;
  position: relative;
  padding: 5px 0px;
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  background: #fff8f8;
  border-color: #ffb3b3;
}

.error-message {
  font-size: 14px;
  color: red;
  font-weight: 400;
  margin: -14px 0 15px 90px;
}

.price-amt {
  float: right;
}

.phone-details-title-div {
  height: 32px;
  font-size: 16px;
  margin-left: 5px;
}

.phone-details-uneditable-div {
  padding: 4px 11px;
  height: 32px;
  border: 1px solid #d3d3d3;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.phone-details-container {
  padding: 10px;
  height: calc(100% - 50px);
  overflow: auto;
}

.phone-details-cont-alt {
  padding: 10px;
  height: calc(100% - 0px);
  overflow: auto;
}

.phone-details-footer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.phone-details-flagicon {
  margin-right: 5px;
}

.phone-details-relations-body {
  height: calc(100% - 25px);
  padding: 5px;
  overflow: auto;
}

.phone-details-relations-head {
  border-bottom: 1px solid #d3d3d3;
  height: 25px;
  text-align: center;
  margin-bottom: 5px;
}

.phone-details-relations-box {
  border: 1px solid #d3d3d3;
  height: fit-content;
  padding: 5px;
}

.phone-details-cancel-button {
  width: 100%;
}

.msteams-cont {
  height: 100%;
}

.country-select-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2em;
}

.country-select-wrapper .country-select {
  margin: 0.75em 3em 0 1em;
}