.add-credit {
  display: flex;
  justify-content: space-between;
}

.add-credit .ant-radio-button-wrapper {
  margin: 0px 7.1px;
  padding: 0px 10px !important;
}

.add-credit .ant-radio-button-wrapper:first-child {
  margin-left: 0 !important;
}

.add-credit .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #7a78b3 !important;
}

.add-credit .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
  background-color: #807eb6 !important;
}

.add-credit
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: var(--userPrimaryColor) !important;
}

.add-credit
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--userPrimaryColor) !important;
}

.credit-select {
  width: 100%;
  margin-bottom: 10px;
}

.field-label {
  margin: 5px 0;
}

/* Add payment card modal */

.StripeElement {
  box-sizing: border-box;
  height: 32px;
  padding: 7px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: white;
  /* box-shadow: 0 1px 3px 0 #e6ebf1; */
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  margin-bottom: 10px;
}

.StripeElement--focus,
.name-on-card:focus,
.amount-to-pay:focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.make-payment-form .StripeElement {
  border-radius: 2px 0 0 2px;
  margin-top: 5px;
}

.name-on-card,
.amount-to-pay {
  display: block;
  width: 100%;
  outline: none;
}

.name-on-card::placeholder,
.amount-to-pay::placeholder {
  color: #bfbfbf;
}

.ElementsApp .InputElement {
  color: rgba(0, 0, 0, 0.65);
}

.billing-tab {
  height: 100%;
}

.billing-tab .ant-tabs-nav {
  margin: 0;
}

.ant-tabs-nav-wrap {
  justify-content: flex-start !important;
}


.payment-edit-pri {
  width: 46%;
}

.payment-edit-sec {
  width: 100%;
  /* margin-right: 8%; */
}

.card-input-style {
  margin: 5px 0;
}

.add-payment-modal .ant-modal-body {
  padding: 0;
}

.err-msg {
  color: red;
  padding-left: 24px;
}

.err-msg.add-cred-err {
  padding: 0px 0 10px;
}

.balance-div {
  padding: 5px 20px 5px 0;
  font-weight: 500;
}

.card-img {
  /* height: 25px; */
  width: 30px;
}

.make-payment-modal .ant-modal-body {
  width: 29.5em;
}

.card-info-img {
  height: 57px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.add-payment-modal-body {
  width: 22em;
}

.voiceuser-info-modal .ant-modal-content,
.voiceuser-info-modal .ant-modal-body {
  width: 50vw;
}

.subscription-info-modal .ant-modal-content,
.subscription-info-modal .ant-modal-body {
  width: 35vw;
}
.payment-method-container {
  height: calc(100% - 45px);
  margin-top: 15px;
}

.payment-body {
  height: 100%;
  margin-top: 10px;
}

.vat-info {
  margin: 5px 10px 0 0;
}

.top-up-btn {
  margin: 5px 0 0 10px;
}

.transaction-footer {
  height: 40px;
  width: 100%;
  background: rgb(250 250 250);
}

.next-btn {
  margin: 0 5px;
}

.billing-tab .ant-tabs-content {
  height: 100%;
}

.billing-main-cont {
  height: 100%;
}

.billing-utils-container {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-end;
}

.billing-utils-parent {
  display: flex;
}

.billing-table-body {
  height: 100%;
  width: 100%;
}

.billing-inner-body {
  display: flex;
  height: 100%;
}

.billing-table-container {
  height: calc(100% - 80px);
  padding-top: 15px;
}

.custom-table-footer {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
}
