.standerd-height {
  height: 100px;
}

.upload-icon-style {
  height: 80%;
  width: 100% !important;
}

.message-style {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 20%;
}

.cdz-spinner-style {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.Audiobar-body {
  height: 140px;
  border: 1px solid lightgrey;
  overflow: auto;
}

.Audio-player-style {
  height: 95px;
  padding: 0px 5px;
}

.Audiobar-body-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* padding-left: 5px; */
}

.Audiobar-body-Icons {
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
}

.Audiobar-body-Icon {
  cursor: pointer;
}

.Audiobar-player {
  width: 100%;
}

.Audiobar-Detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0px 5px;
}
