.MapNumberOrStringToNode__Row {
  padding: 5px;
  border: 1px solid lightgrey;
  margin: 5px;
}

.MapNumberOrStringToNode__RowInner {
  display: flex;
  align-items: center;
}

.MapNumberOrStringToNode__ShortNumber {
  width: 25%;
  margin-right: 5px;
}

.MapNumberOrStringToNode__ShortText {
  width: 73%;
}

.MapNumberOrStringToNode__RowSpace {
  margin-bottom: 5px;
}

.MapNumberOrStringToNode__Info {
  font-size: 12px;
  white-space: nowrap;
  padding-right: 5px;
}

.MapNumberOrStringToNode__Select {
  width: calc(100% - 80px);
}

.MapNumberOrStringToNode__Delete {
  margin-left: 7px;
}

.MapNumberOrStringToNode__Box {
  border: 1px solid lightgrey;
  height: 250px;
  padding: 5px;
}

.MapNumberOrStringToNode__Body {
  color: grey;
  height: calc(100% - 27px);
  overflow: auto;
}

.MapNumberOrStringToNode__Title {
  height: 35px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  border-bottom: 0px;
}

.MapNumberOrStringToNode__Say {
  margin-left: 40px;
}

.opt-image {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
