.info-panel-container {
  padding-left: 5px;
  height: 100%;
}

.info-panel-border {
  border-left: 1px solid #dcdcdc;
  height: 100%;
}

.header-container {
  align-items: center;
  display: flex;
  height: 40px;
}

.body-container {
  overflow: auto;
  height: calc(100% - 40px);
}

.close-container {
  display: flex;
  justify-content: flex-start;
  width: 20%;
  padding-left: 15px;
}

.content-hidden {
  visibility: hidden;
}

.topic-container {
  display: flex;
  justify-content: center;
  width: 60%;
}

.more-action-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: 900;
  width: 20%;
}
